export function getWarehousesList(params) {
  return this.$api.get('store/list', params)
    .then(({ data }) => {
      return data
    })
    .catch(error => Promise.reject(error))
}

export default {
  methods: {
    getWarehouses(params) {
      return this.$api.get('stores', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    getWarehouse(id) {
      return this.$api.get('store/' + id)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    createWarehouse(params) {
      return this.$api.post('store/0', params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    },

    updateWarehouse(id, params) {
      return this.$api.post('store/' + id, params)
        .then(({ data }) => {
          return data
        })
        .catch(error => Promise.reject(error))
    }
  }
}
